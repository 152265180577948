import React, { useState } from 'react'

import CommonBanner from '../components/CommonBanner/CommonBanner'

import TemplateWrapper from '../components/Layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import ConclaveSlider from '../components/GallerySlider/ConclaveSlider'
import ConclaveProfileSlider from '../components/ConclaveProfileSlider'
import GetInTouch from '../components/GetInTouch/GetInTouch'
import SeoHeader from '../components/SeoHeader'
import Webinar from '../components/ModalPopup/webinarPopup'
import BigButton from '../components/button/BigButton'
import Timer from '../components/Timer/Timer'
const ServiceContent = styled.div`
  p {
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.5px;
    margin-top: 20px;
    color: #4d6e81;
  }
  h6 {
    text-align: center;
  }
  h4 {
    margin-top: 25px;
  }
  h5 {
    margin-top: 25px;
    font-size: 1.25rem;
  }
  ul {
    margin-top: 20px;
  }
  ol {
    margin-top: 20px;
    // font-size: 20px;
    // font-weight: bold;
  }
`

export const WebinarPage = ({ acf, title, featuredImage }) => {
  console.log('acf', acf.gallery.slider2)
  const [display, setDisplay] = useState(false)

  const open = () => {
    if (window !== 'undefined') {
      setDisplay(true)
      document.body.style.overflow = 'hidden'
    }
  }
  const close = () => {
    setDisplay(false)
    document.body.style.overflow = 'unset'
  }

  return (
    <main>
      {display && <Webinar subject="Webinar" close={close} />}
      <CommonBanner
        // align="left"

        description=""
        image={featuredImage}
        headerData={acf.header}
        webinarHeader={
          'Master Strategies for a Seamless and Successful Cards Transition!'
        }
        // pageClass="industry v3"
        webinar
        webinarRightImg={acf.header.side_image}
      />

      {acf.data.map((items, index, arr) => {
        return (
          <div
            className={` section_padd personal_details_wrapper ${
              (index % 2) + 1 === 1 ? 'service-product-bg' : ''
            }`}
            key={index}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className=" mb_15 ">
                    <h2
                      className="title_sm text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {items.heading}
                    </h2>
                    <ServiceContent
                      dangerouslySetInnerHTML={{ __html: items.content }}
                      className="service-product webinarlist"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div className="container section_padd">
        
              <Timer time={'2024-09-24T15:00:00.00+05:30'} open={open} />
          </div>
          
            

      <div className=" container">
        <div className="row justify-content-center">
          <div className="col-xl-12 text-center">
            <h2 className="text-ol mb-5">Key speakers of the event</h2>
          </div>
          <div className="col-12 text-center">
            <ConclaveProfileSlider slideData={acf.speakers.contents} />
          </div>
        </div>
      </div>
      <div className="gallery section_padd overflow-hidden pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-5 text-center">
              <h2 className="text-ol">Gallery</h2>
            </div>
            <div className="col-12 p-0">
              <ConclaveSlider
                image2={acf.gallery.slider2}
                image={acf.gallery.slider1}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

const Conclave = ({ data }) => {
  return (
    <TemplateWrapper>
      <SeoHeader
        // seoData={data.wordpressPage.acf.panel}
        alternative={`Verinite | Mastering Cards System Migration `}
        linkhref={`https://verinite.com/${data.wordpressPage.slug}/`}
      />
      <WebinarPage
        acf={data.wordpressPage.acf}
        featuredImage={
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid.src
        }
      />
      <GetInTouch />
    </TemplateWrapper>
  )
}
export default Conclave

export const CConclaveQuery = graphql`
  query WebinarQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      slug
      featured_media {
        source_url
        title
        localFile {
          childImageSharp {
            fluid {
              src
              srcSet
            }
          }
        }
      }
      acf {
        speakers {
          contents {
            profile_pic {
              id
              source_url
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            linkedin
            name
            designation
            company
          }
        }
        panel {
          title
          description
          keywords
          schema
        }
        gallery {
          slider1 {
            image {
              url
            }
          }
          slider2 {
            image {
              url
            }
          }
        }

        data {
          content
          heading
        }
        header {
          heading
          date
          description
          side_image {
            localFile {
              id
              childImageSharp {
                id
                gatsbyImageData
              }
              url
            }
          }
          time
          webinar_topic
        }
      }
    }
  }
`
