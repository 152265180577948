import React from 'react'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'
import '@leenguyen/react-flip-clock-countdown/dist/index.css'
import './timer.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import BigButton from '../button/BigButton'
const Timer = ({ time , open}) => {
  const { backgroundImageFile } = useStaticQuery(graphql`
    query Background {
      backgroundImageFile: file(absolutePath: { regex: "/topography_blue/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const backgroundImage = backgroundImageFile && getImage(backgroundImageFile)

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 ">
            <div className="quote_box text-center">
              {backgroundImage && (
                <GatsbyImage
                  className="background_image"
                  image={backgroundImage}
                />
              )}
              <h2 className="title" data-aos="fade-up" data-aos-delay="400">
                Hurry UP!
              </h2>
              <p
                className="article_text text-white"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                Don’t miss this opportunity to gain valuable insights into
                mastering cards system migration. Register today to secure your
                spot in this exclusive FREE webinar! Limited Seats!
              </p>

              <div className="d-flex justify-content-center mt_40"data-aos="fade-up" data-aos-delay="600">
                <FlipClockCountdown
                  to={time}
                  hideOnComplete={false}
                  labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                  className="flip-clock"
                  duration={0.5}
                />
              </div>
              <div className='text-center' data-aos="fade-up" data-aos-delay="800">

              <BigButton
              btnClass="button black_btn"
              btnText="Register Now"
              click={open}
            />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Finished */}
    </>
  )
}

export default Timer
